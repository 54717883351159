@import "../../common/constants";

.Desktop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: $taskbar-height;

  &.hearts {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .8;
      pointer-events: none;
      background-image: url(../../images/hearts.png);
      background-repeat: repeat;
      background-size: auto 100vh;
      animation: falling-hearts 10s linear infinite, opening 3s;
    }

    @keyframes falling-hearts {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 0 100vh;
      }
    }
  }

  .wallpaper {
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: grayscale(1);
    transition: filter 3s;

    &.fade-in {
      animation: 3s opening;

      @keyframes opening {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }

    &.color {
      filter: none;
    }
  }

  .location {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    font-size: $font-size-small;
    color: white;
    display: flex;
    align-items: center;
    opacity: .4;

    .pinpoint {
      width: 1em;
      height: 1em;
      background-image: url(../../images/pinpoint.png);
      background-size: contain;
      background-position: center;
      margin-right: .2rem;
    }
  }

  .app-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 32rem;
    justify-content: center;
    flex-shrink: 1;
    overflow-y: auto;
    padding: 1rem;

    .shortcut {
      @include hoverable;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 8rem;
      height: 8rem;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      border-radius: .2rem;

      &.link-external {
        .name {
          position: relative;

          &:after {
            position: absolute;
            content: '';
            display: inline-block;
            margin-left: .3rem;
            width: .8em;
            height: 100%;
            background: url(../../images/link-external.png) no-repeat bottom;
            background-size: contain;
            opacity: .5;
          }
        }
      }

      .icon {
        @include icon(4rem);
        margin-bottom: .2rem;
      }

      .name {
        font-size: $font-size-small;
        color: white;
        text-shadow: 0 0 .4rem black;
        text-align: center;
        height: 1em;
      }

      &:hover {
        box-shadow: inset 0 0 2px rgba(black, .2);
      }

      &:active {
        box-shadow: inset 0 0 2px rgba(black, .4);
      }
    }

  }

  .window-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }
}
