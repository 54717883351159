@import "../../common/constants";

.TerminalWindow {
  &.focused {
    .line-container {
      .cursor {
        border: none;
        margin: 0;
        background-color: #2FCB29;
        color: black;
        @keyframes blink {
          from {
            background-color: #2FCB29;
            color: black;
          }
          50% {
            background-color: transparent;
            color: #2FCB29;
          }
        }
        animation: blink 1s 1s infinite step-end;
      }
    }
  }

  .line-container {
    flex: 1;
    background-color: rgba(black, .9);
    padding: .2rem;
    overflow-y: auto;
    font-family: 'Roboto Mono', sans-serif;
    color: #2FCB29;
    font-weight: lighter;
    white-space: pre-wrap;
    word-break: break-all;

    .cursor {
      border: solid 1px #2FCB29;
      margin: -1px;
      box-sizing: border-box;

      &:empty {
        &:before {
          content: '\00a0';
        }
      }
    }

    .word-break {
      word-break: break-word;
    }

    .highlight {
      font-weight: bold;
    }

    .underline {
      text-decoration: underline;
    }

    .dir {
      color: #65A6CA;
      font-weight: bold;
    }

    .file {
      color: #9DD55E;
    }
  }

  .mobile-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
}
