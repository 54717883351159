@import "../../common/constants";

.AlbumWindow {
  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    color: white;
    margin-top: 4rem;
    text-shadow: 0 0 16px rgba(black, .5);
  }

  .memory-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 4rem;

    .memory {
      margin: 4rem;
      background-color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      transform: rotate(-3deg);
      box-shadow: 0 0 16px rgba(black, .3);

      .image {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 1rem;
      }

      .note {
        padding: 1rem;
        text-align: center;
      }

      &:nth-child(odd) {
        transform: rotate(3deg);
      }
    }
  }
}
