@import "../../common/constants";

.Taskbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  height: $taskbar-height;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  background-color: rgba(black, .2);

  .label {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    white-space: nowrap;

    &.label-profile {
      @include hoverable;

      .avatar {
        @include icon(2rem);
        filter: none;
        margin-right: .5rem;
        background-size: cover;
        background-position: center;
      }
    }

    &.label-clock {
    }

    .name {
      font-size: $font-size-small;
      color: white;
    }
  }

  .shortcut-container {
    flex: 1;
    display: flex;
    overflow-x: auto;

    .shortcut {
      @include hoverable;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: flex-start;
      overflow: hidden;
      max-width: 0;
      opacity: 0;
      transition: max-width $anim-duration,
      opacity $anim-duration,
      background-color $anim-duration;

      .icon {
        @include icon(2.2rem);
        margin: 0 1rem;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: solid 3px white;
        opacity: 0;
        transition: opacity $anim-duration;
      }

      &.active {
        max-width: 4.2rem;
        opacity: 1;

        &:after {
          opacity: 1;
        }
      }

      &.pinned {
        max-width: 4.2rem;
        opacity: 1;
      }
    }
  }
}
